<template>
  <div class="preview dark"
    v-loading="loading"
    element-loading-text="加载中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header dark">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/" >
          <el-breadcrumb-item
            v-for="(n, i) in settingTarget.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame dark">
          <div class="line"
               style="padding:24px 0">
            <div class="setting-title">
              <h1>空间基本信息</h1>
              <span>修改您的空间基本信息</span>
            </div>
            <el-row :gutter="24">
              <el-col :span="16">
                <div class="insert-h">
                  <div class="attr">空间名称</div>
                  <div class="val">
                    <el-input v-model="settingTarget.name"
                              placeholder="请输入空间名称" />
                  </div>
                </div>
                <div class="insert-h">
                  <div class="attr">空间描述<span>（非必填）</span></div>
                  <div class="val">
                    <el-input v-model="settingTarget.description"
                              placeholder="请输入空间名称"
                              :rows="4"
                              type="textarea" />
                  </div>
                </div>
                <div class="right" v-if="editAuth">
                  <el-button type="primary" @click="save()">保存</el-button>
                </div>
              </el-col>
              <el-col :span="2"></el-col>
              <el-col :span="6">
                <div class="insert-h">
                  <div class="attr">空间头像</div>
                  <div class="val">
                    <div class="demo-block left">
                      <el-upload class="avatar-uploader"
                               v-if="editAuth"
                               action="#"
                               :show-file-list="false"
                               :http-request="handleAvatarSuccess">
                        <img v-if="settingTarget.pic && settingTarget.pic != '-1'"
                            :src="'/file/'+ settingTarget.pic"
                            class="avatar" />
                        <div class="avatar-uploader-icon icomoon-plus" v-if="!settingTarget.pic || settingTarget.pic == '-1'"></div>
                      </el-upload>
                      <div class="img-view" v-if="!editAuth">
                        <img v-if="settingTarget.pic && settingTarget.pic != '-1'"
                            style="width: 180px;height: 180px;"
                            :src="'/file/'+ settingTarget.pic"
                            class="avatar" />
                      </div>
                      
                      <el-button class="icomoon-bin"
                              v-show="settingTarget.pic && settingTarget.pic != '-1' && editAuth"
                              @click="settingTarget.pic = '-1'"
                              title="clear picture"
                              style="margin-left:10px"></el-button>
                    </div>
                    
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="setting-title"
                 style="margin-top:60px" v-if="roleAuth()">
              <h1>高级</h1>
            </div>
            <el-row :gutter="24" v-if="roleAuth()">
              <el-col :span="16">
                <div class="remove-area">
                  <div class="right"
                       style="margin:6px 0 0">
                    <el-button type="danger" @click="deleteShow()">删除空间</el-button>
                  </div>
                  <h1>删除空间</h1>
                  <span>您确定要删除此空间？</span>
                </div>
              </el-col>
              <el-col :span="8"></el-col>
            </el-row>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <window windowId="del-prj"
            :shadow="true"
            class="dark"
            :title="deletePrj.title"
            @windowHide="deleteHide"
            v-show="deletePrj.show">
      <template #body>
        <div class="line pd-20">
          <div class="insert-h">
            <div class="attr">空间名称</div>
            <div class="val">
              <el-input v-model="deletePrj.confirmName"
                        placeholder="请输入空间名称" />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="deleteHide()">取消</el-button>
          <el-button type="primary"
                     @click="deleteSetting()">保存</el-button>
        </div>
      </template>
    </window>
  </div>
</template>

<script>
import Window from 'components/common/forms/Window';
import { ref, reactive, getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { btnStatus, jumpRouteSetup } from 'common/authBtn';
export default {
  name: 'Setting',
  components: {
    Window
  },
  setup(props, {emit}) {

    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
    const $message = getCurrentInstance().appContext.config.globalProperties.$message;
    const $router = useRouter();
    const $store = useStore();
    let parentObj = reactive({});
    let settingTarget = ref({});
    let editAuth = ref(false),  delAuth = ref(false), loading = ref(true), deletePrj = ref({
      show: false,
      title: '删除空间',
      confirmName: ''
    });
    const getDetail = () => {
      $api.sp.getDetailById().then((res) => {
        loading.value = false;
        if(res) {
          settingTarget.value = res; 
          parentObj.type = res.type;
          btnStatus(res.id, $store, (result) => {
            editAuth.value = result.editAuth;
            delAuth.value = result.delAuth;
          });
        }
      })
    };
    const save = () => {
      if(parentObj.type == 1) {
        $api.sp.updateSpaceInfo(settingTarget.value).then((res) => {
          if(res) {
            getDetail();
            $message.success('操作成功');
          }
        })
      }
    };
    const deleteSetting = () => {
      if(parentObj.type == 1 && settingTarget.value.name == deletePrj.value.confirmName) {
        $api.sp.deleteSpaceInfo({id: settingTarget.value.id}).then((res) => {
          if(res) {
              $router.push('/Mgt/SpaceMgt');
              $message.success('操作成功');
            }
        })
      }else {
        $message.error('请输入正确名称');
      }
    };
    const roleAuth = () => {
        let roleInfos = $store.state.user ? $store.state.user.roleInfos : null;
        if(roleInfos && roleInfos.length > 0) {
          let roleId = roleInfos[0].id;
          if("-2" == roleId ) {
            return true;
          } 
        }
        if(delAuth.value) {
          return true;
        }
        return false;
    };

    const handleAvatarSuccess = (data) => {
      $api.file.uploadFile(data.file).then((res) => {
        settingTarget.value.pic = res.id;
      })
    };

    const jumpSpace = (n) => {
      if(settingTarget.value.id != n.id) {
        jumpRouteSetup(emit, n).then(() => {
          getDetail();
        })
      }
    };

    const deleteHide = () => {
      deletePrj.value.show = false;
    };

    const deleteShow = () => {
      deletePrj.value.show = true;
    };

    onMounted(() => {
      getDetail();
    });

    return {
      deletePrj,
      loading,
      parentObj,
      settingTarget,
      editAuth,
      delAuth,
      deleteSetting,
      roleAuth,
      save,
      handleAvatarSuccess,
      deleteShow,
      deleteHide
    }
  },
}
</script>

<style lang="scss" scoped>
.setting-title {
  width: 100%;
  float: left;
  margin-bottom: 20px;

  h1,
  span {
    width: 100%;
    float: left;
    margin: 0 0 8px;
    line-height: 1;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
  }

  span {
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
  }
}

.remove-area {
  width: 100%;
  float: left;
  border-radius: 4px;
  padding: 20px;
  border: 1px dashed rgba(255, 0, 0, 0.5);
  background: rgba(255, 0, 0, 0.1);

  h1,
  span {
    width: calc(100% - 150px);
    float: left;
    margin: 0 0 8px;
    line-height: 1;
  }

  h1 {
    font-size: 18px;
    color: rgb(223, 11, 11);
  }

  span {
    font-size: 14px;
  }
}
</style>